.root {
  overflow: hidden !important;
  flex-direction: column !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  display: flex !important;
  flex-wrap: nowrap !important;
  font-family: "Roboto", sans-serif;
}

a,
a:visited,
a:hover {
  text-decoration: none !important;
}